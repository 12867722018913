import React from "react";
import tw, { styled } from "twin.macro";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SvgVisionaryTechnology from "../components/SvgVisionaryTechnology";

const TextContainer = styled.div`
  ${tw`
    flex flex-col 
    justify-center items-center
    w-full h-full
  `}

  z-index: 2;
`;

const Header = styled.h4`
  ${tw`
    text-black text-center font-normal
    pb-8
  `}
`;

const Body = styled.p`
  ${tw`
    pb-4 font-normal
  `}
`;

const SvgContainer = styled.div`
  ${tw`max-h-[30rem] mb-12`}

  > svg {
    ${tw`
      h-full
      w-auto
    `}
  }
`;

const ThankYouPage = () => (
  <Layout hideContact={true}>
    <Section tw="my-16">
      <SvgContainer>
        <SvgVisionaryTechnology />
      </SvgContainer>
      <TextContainer>
        <Header>Thanks for reaching out</Header>
        <Body>We'll get back to you as soon as we can!</Body>
      </TextContainer>
    </Section>
  </Layout>
);

export default ThankYouPage;
